.login-page {
    height: 100vh;
    width: 100%;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;


    button.login-box-button {
        border: none;
        background-color: white;
    }

    .login-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 38%;
        padding: 50px;
        background: rgba(0, 0, 0, 0.5);

        border-radius: 10px;

        .heading-login-box {
            margin-top: 20px;
            margin-bottom: 20px;
            color: #fff;
            text-align: center;
        }
    }

    input:focus~label,
    .user-box input:valid~label {
        top: -20px;
        left: 0;
        color: white;
        font-size: 16px;
    }

    .login-box-button {
        position: relative;
        display: inline-block;
        padding: 10px 20px;
        color: black !important;
        font-weight: 700;
        font-size: 16px;
        text-transform: capitalize;
        text-decoration: none;
        overflow: hidden;
        transition: .5s;
        margin-top: 30px;
        border: none;
        background-color: white !important;
    }

    .login-box-button span {
        position: absolute;
        display: block;
    }

    .login-box-button span:nth-child(1) {
        top: 0;
        left: -100%;
        width: 100%;
        height: 2px;
        background: linear-gradient(90deg, #fff, grey);
        animation: btn-1 1.7s linear infinite;
    }

    .login-box-button span:nth-child(2) {
        top: -100%;
        right: 0;
        width: 2px;
        height: 100%;
        background: linear-gradient(180deg, #fff, grey);
        animation: btn-2 1.7s linear infinite;
        animation-delay: .25s;
    }

    .login-box-button span:nth-child(3) {
        bottom: 0;
        right: -100%;
        height: 2px;
        width: 100%;
        background: linear-gradient(270deg, #fff, grey);
        animation: btn-3 1.7s linear infinite;
        animation-delay: .5s;
    }

    .login-box-button span:nth-child(4) {
        left: 0;
        bottom: -100%;
        width: 2px;
        height: 100%;
        background: linear-gradient(360deg, #fff, grey);
        animation: btn-4 1.7s linear infinite;
        animation-delay: .7s;
    }

    .user-box {
        position: relative;
        margin-bottom: 30px;
    }

    .user-box-input {
        width: 100%;
        padding: 10px 0;
        font-size: 16px !important;
        border: none;
        color: white;
        border-bottom: 1px solid white;
        outline: none;
        background: transparent;
    }

    .user-box-label {
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px 0;
        font-size: 16px;
        color: #fff;

        pointer-events: none;
        transition: .5s;
    }

    label.user-box-label {
        margin-top: -9px;
    }

}

.remember {
    display: flex;
    align-items: center;

    label {
        color: white;
        font-size: 14px;
        text-transform: capitalize;
    }

    input[type="checkbox"] {
        cursor: pointer;
    }
}

@keyframes btn-1 {
    0% {
        left: -100%
    }

    50%,
    100% {
        left: 100%;
    }
}


@keyframes btn-2 {
    0% {
        top: -100%
    }

    50%,
    100% {
        top: 100%;
    }
}



@keyframes btn-3 {
    0% {
        right: -100%
    }

    50%,
    100% {
        right: 100%;
    }
}


@keyframes btn-4 {
    0% {
        bottom: -100%
    }

    50%,
    100% {
        bottom: 100%;
    }
}


@media screen and (max-width:576px) and (min-width:400px) {
    .login-box {
        width: 100%;
    }
}

@media screen and (max-width:576px) {
    .heading-login-box {
        font-size: 16px !important;
    }

    .user-box-input {
        font-size: 12px;
    }

    .user-box-label {
        font-size: 12px;
    }

    .user-box-button {
        font-size: 12px;
    }
}


.login-page-opacity {
    background-color: rgba(0, 0, 0, 0.4);
    height: 100%;
    width: 100%;
}


input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: transparent !important;
    color: fieldtext !important;
}