.add_modal{
  
    .modal-content {
        border-radius: 0;
    }
    .modal-body {
        padding: 30px;
    }
    .cancel_btn{
        border: 1px solid var(--cancel-btn-border-color);
        margin-right : 18px;
        color: var(--cancel-btn-text-color);
    }
    .form_btn{
    button{
        padding: 10px 44px 10px 57px;

    }
    .btn{
        background-color: #3B84DF !important;
    }
}
}
.delete_modal{
    h5 {
        font-size: 18px;
    }
}
