body {
  height: 100vh;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
p,
span,
div {
  margin: 0;
  padding: 0;
}

a {

  transition: background 0.2s, color 0.2s;
}

a:hover,
a:focus {
  text-decoration: none;
}

.heading_none {
  .lmojr {
    display: none
  }
}


// -------------------------//



//other library //

@import 'bootstrap/dist/css/bootstrap.min.css';
@import '@fortawesome/fontawesome-free/css/all.min.css';

//variable//
@import './assets/scss/variable/variable.scss';

// component // 
@import './assets/scss/component/sidebar.scss';
@import "./assets/scss/component/table.scss";

//pages //
@import './assets/scss/pages/login.scss';
@import './assets/scss/pages/dashboard.scss';
@import './assets/scss/pages/addfilter.scss';
@import './assets/scss/pages/settings.scss';
@import './assets/scss/pages/sb.scss';

//media//
@import './assets/scss/media/1190.scss';
@import './assets/scss/media/sidebarResponsive.scss';
@import './assets/scss/media/520.scss';
@import "./assets/scss/media/filterResponsive.scss";
@import './assets/scss/media/991.scss';



//other//
@import './assets/scss/other/common.scss';
@import "./assets/scss/other/filter.scss";
@import "./assets/scss/other/modal.scss";