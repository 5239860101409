@media only screen and (max-width: 520px) {

  .history-filter {

    grid-template-columns: 1fr !important;

  }

  .outlet_wrapper {
    top: 64px !important;
    left: 0 !important;
    width: 100vw !important;
  }

  .price_section {
    grid-template-columns: 1fr;
  }

  .box.box_first,
  .box.box_third {
    margin-right: 0;
  }

  .box.box_third {
    margin-bottom: 13px;
  }

  .data_content {
    .expand_btn {
      display: grid;
      justify-content: center;
      grid-template-columns: 1fr;

      .search-btn {
        width: 100%;

        .inner-search-btn {
          width: 100%;

          input {
            width: 80%;
            float: left;

          }

          button {
            width: 20%;

          }
        }

      }
    }
  }

  //filter  //
  .filter_section {
    width: 100% !important;
    padding: 18px 16px 0 !important;
    grid-template-columns: 1fr !important;
    padding-top: 96px !important;

    .select-picker {
      width: 100% !important;
    }

  }


  .inner_filter:nth-child(1) {

    margin-bottom: 16px !important;

  }

  .inner_filter:nth-child(2) {

    margin-bottom: 16px !important;
  }

  .inner_filter:nth-child(3) {
    margin-bottom: 16px !important;
  }

  .inner_filter:nth-child(4) {

    width: 100%;
    margin-bottom: 16px !important;

  }

  .filter_section {
    padding-top: 0px !important;
  }

  .filter_section select,
  .select_dropdown__control {

    font-size: 12px;

  }

  .filter_section .inner_filter .heading {
    font-size: 14px;

  }

  .filter_section .date_range .rmdp-container input.rmdp-input {
    font-size: 12px;
  }

  .middle_container {
    box-shadow: none !important;
    padding: 0 !important;
    margin: 16px;
  }

  // sidebar //
  #sidebar-wrapper {
    width: 100%;
    height: auto;
  }

  .sidebar_container .sidebar-nav {
    display: none;
  }

  .toggled .sidebar_container .sidebar-nav {
    display: block;
  }

  .toggled #sidebar-wrapper {
    height: 100%;
  }

  #wrapper {
    padding-left: 0;
  }

  .data_content h5 {
    font-size: 14px;
  }

  .trend_graph .data_content.perfromance_chart {
    width: 100%;
  }

  .sidebar_container .sidebar-nav {
    width: 100%;

  }

  .router_container {
    margin-top: 20px;
  }

  .router_container {
    padding: 16px;

  }

  .chartPerformanceContainer {
    height: 292px;
    padding: 15px;
  }

  .data_content_btn .with_filter .filter_data {
    display: grid;
  }

  .data_content_btn .with_filter {
    display: block;

    button {
      width: 50%;
      justify-content: center;
    }
  }

  //   .rs-anim-fade.rs-anim-in.placement-left-start.rs-picker-daterange-menu.rs-picker-menu {
  //     left: 32px !important;
  //     top: 401px !important;
  // }

  .setting-box {
    width: 90% !important;
    top: 66% !important;
  }

  .setting-box h3 {
    font-size: 18px !important;
  }

  .setting-box button {
    font-size: 14px;
  }

  // .acos_filter table {
  //   width: 90%;
  // }
}


.profile_section {
  button {
    width: 100%;
  }
}