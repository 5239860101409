.sb_container {
    .campaign_btn {
        margin: 10px;
        align-items: center;
        display: grid;
        grid-template-columns: 2fr 3fr 6fr 5fr;
        grid-gap: 8px;
    }

    .sb_table {

        div#cell-3-undefined,
        [data-column-id="3"] {

            min-width: 200px !important;
        }

        div#cell2-undefined,
        [data-column-id="2"] {
            padding-right: 0px !important;
            min-width: 60px !important;
        }
    }

    .sb_sub {
        div#cell-1-undefined {
            margin-left: 47px !important;
        }

        div#cell-3-undefined,
        [data-column-id="3"] {

            min-width: 200px !important;
        }

        div#cell-2-undefined,
        [data-column-id="2"] {
            padding-right: 0px !important;
            min-width: 60px !important;
        }
    }

}
