.data_content {
  margin-top: 16px;
  border-radius: 8px;
  border: 1px solid #e4e4e7;
  // background: var(--table-bg-color);
  background: white;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);

  h5 {
    padding: 15px 16px 15px 16px;
    color: var(--table-title-color);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    border-bottom: 1px solid #e4e4e7;
  }

  .data_table {
    margin-top: 16px;

    .dnuUOg {
      z-index: 0;
    }

    .gnLFIy {
      background-color: white;
    }

    .status {
      border-radius: 2px;
      padding: 5px 8px;
      font-size: 12px;
    }

    .pending,
    .pause {
      background: #d9ecff;
    }

    .completed,
    .end,
    .enable {
      background: #def2d0;
    }

    .cancelled,
    .archived {
      background: #ffdcdc;
    }

    .table_content {
      .blue {
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
      }

      .rdt_TableHeadRow {
        min-height: 0;
        padding: 12px 0;
        background: var(--table-header-bg);
        color: var(--table-header-text);
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 700;
      }

      .rdt_TableRow {
        color: var(--table-text-color) !important;
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
      }

      // .rdt_TableCell {
      //     width: 180px;
      // }
    }

    .rdt_Pagination {
      background-color: var(--table-pagination-bg-color) !important;
    }
  }
}

.data_content_btn {
  h5 {
    padding: 0;
    border-bottom: none;
  }

  .data_model_btn {
    display: flex;
    border-bottom: 1px solid #e4e4e7;
    justify-content: space-between;
    padding: 10px 10px 10px 10px;
    align-items: center;

    button {
      border-radius: 5px;
      background: var(--add-bg-color);
      border: none;
      color: var(--add-text-color);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      display: inline-flex;
      padding: 8px 10px;
      align-items: center;
      gap: 10px;
    }

    .filTypeBox {
      display: flex;
    }
  }

  .with_filter {
    display: flex;

    .filter_data {
      display: flex;
    }

    select {
      border: 1px solid lightgray;
    }
  }
}

.action_icon {
  cursor: pointer;

  i.fa.fa-pencil {
    color: green;
    padding-right: 10px;
  }

  i.fa.fa-trash {
    color: red;
  }

  i.fa.fa-sign-in {
    padding-left: 10px;
    color: blue;
  }
}

.sub_table {
  .rdt_TableHead {
    display: none;
  }

  .rdt_TableCell {
    background: #f2f2f5;
  }
}

.campaign_table {

  .rdt_TableCell,
  .rdt_TableCol {
    padding-left: 0;
    padding-right: 0;
    min-width: 60px !important;
  }

  div#cell-1-undefined {
    margin-right: 5px;
  }

  div#cell-3-undefined,
  [data-column-id="3"] {
    padding-right: 5px;
    min-width: 200px !important;
  }

}


.campaign_sub {
  div#cell-1-undefined {
    margin-left: 108px !important;
  }

  div#cell-2-undefined,
  [data-column-id="2"] {
    padding-right: 5px;
    min-width: 200px !important;
  }

  div#cell-3-undefined,
  [data-column-id="3"] {

    min-width: 60px !important;
  }

  // div#cell-2-undefined {
  //   margin-left: 5px !important;
  // }

  .rdt_TableRow {
    background: #f2f2f5;
  }
}

.vertical_table {
  table {
    width: 100%;
    background: white;
  }

}


// Variables
$line-height-base: 20px;
$line-height-computed: $line-height-base / 2;
$table-cell-padding: 13px !default;
$table-condensed-cell-padding: 5px !default;
$table-bg: transparent !default;
$table-bg-accent: #f9f9f9 !default;
$table-border-color: #ddd !default;

.expand_table {

  // Baseline styles

  table {
    border: 1px solid $table-border-color;
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: $line-height-computed;
    max-width: 100%;
    width: 100%;

    // Cells
    >thead,
    >tbody,
    >tfoot {
      >tr {

        >th,
        >td {
          border: 1px solid $table-border-color;
          line-height: $line-height-base;
          padding: $table-cell-padding;
          vertical-align: top;
        }
      }
    }

    >thead>tr>th {
      background: #f5f7fb;
      color: black;
      font-weight: normal;
      vertical-align: bottom;
    }

    th {
      text-align: left;
    }
  }

  // All block elements
  table {

    &,
    thead,
    tbody,
    tfoot {

      &,
      tr {

        &,
        th,
        td {
          display: block;
        }
      }
    }
  }

  // Horizontal
  table {
    >thead {
      float: left;
    }

    >tbody {
      >tr {
        display: inline-block;
        vertical-align: top;
      }
    }
  }

  // Horizontal scroll
  table {
    max-width: 1700px;
    margin: auto;
    border: none;
  }

  table {
    >tbody {

      white-space: nowrap;
      overflow-x: auto;
      direction: rtl;
    }
  }
}


.date_show {
  min-height: 69px;
  text-align: center;
  display: flex;
  align-items: center;
  font-weight: 900;
}

.cal {
  text-align: center;
}


.scrollable_table {
  .rdt_TableCell div:first-child {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .rdt_TableCol_Sortable div {
    white-space: normal;
  }
}


// buttons //