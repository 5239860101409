.filter_section {
    margin-left: auto;
    padding: 10px 30px 10px;
    display: flex;
    justify-content: end;
    align-items: center;
    width: 60%;

    .inner_filter {
        .heading {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            color: var(--title-text-color);
        }
    }

    :last-child {
        margin-right: 0;
    }

    select {
        width: 100%;
        border: none;
        padding: 10px;
        outline: none;
        color: #71717A;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        border-radius: 5px;
        background: #F6F7FB;
        box-shadow: -8px 12px 18px 0px rgba(25, 42, 70, 0.00);
    }

    .date_range {
        .rmdp-container {
            width: 100% !important;

            input.rmdp-input {
                width: 100%;
                background: #F6F7FB;
                box-shadow: -8px 12px 18px 0px rgba(25, 42, 70, 0);
                border: none;
                border-radius: 5px;
                padding: 20px 34px;
                outline: none;
                color: #71717A;
                font-size: 14px;
                font-weight: 500;
                line-height: 21px;
            }
        }

        .rs-btn-close .rs-icon {
            display: none;
        }
    }

    span.select_dropdown__indicator-separator {
        background-color: transparent;
    }

    .select_dropdown__indicator.select_dropdown__dropdown-indicator {
        padding: 11px;
    }

    .select_dropdown__control {
        background: #F6F7FB;
        color: #71717A;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        box-shadow: -8px 12px 18px 0px rgba(25, 42, 70, 0.00);
        border: none;
        outline: none;
        color: #71717A;
        padding-left: 34px;
    }

    .inner_filter.date_range {
        position: relative;
        z-index: 12;

        i.fa.fa-home,
        i.fa.fa-calendar {
            position: absolute;
            top: 15px;
            left: 11px;
            color: #71717A;
        }
    }

    .select_icon.inner_filter {
        position: relative;
        z-index: 12;

        .img {
            position: absolute;
            width: 20px;
            height: 20px;
            top: 24%;
            left: 3%;

            color: #71717A;
            z-index: 1;

        }
    }

    .select_dropdown__value-container {
        padding: 0;
    }

    .rs-picker-toggle.rs-btn.rs-btn-default {
        background: #F6F7FB !important;
        color: #71717A !important;
        border: none !important;
        padding: 11px !important;
        outline: none !important;
        width: 100%;
    }

    .filter_date {
        width: 100%;
    }

    .rs-picker-toggle-caret {

        top: 11px !important;
    }
}

.select_dropdown__indicator {
    z-index: 999 !important;
}

.profile_section {

    width: 100%;
    .dropdown-btn {
        background: #244d72;
    }

    button#dropdown-basic {
        border-radius: 77px;
        background: #001E4E !important;
        color: white;
        width: 100%;

    }

    a.dropdown-item {
        color: #001E4E !important;
    }

    i.fa.fa-bell {
        color: black !important;
        display: flex;
        width: 50px;
        height: 50px;
        padding: 8px;
        gap: 8px;
        background: white;
        border-radius: 77px;
        align-items: center;
        justify-content: unset;
        margin-left: 24px;
    }
}


.rs-check-item:not(.rs-checkbox-disabled):hover {
    background: #244e729f;
    color: #244d72;
}

.select-picker {
    .select_dropdown {
        background: #F6F7FB;
        color: #71717A;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        box-shadow: -8px 12px 18px 0px rgba(25, 42, 70, 0.00);
        border: none;
        outline: none;
        color: #71717A;
        padding-left: 34px;
        border-radius: 5px;
        width: 100%;
    }

    background: #F6F7FB;
    color: #71717A;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    box-shadow: -8px 12px 18px 0px rgba(25, 42, 70, 0.00);
    border: none;
    outline: none;
    color: #71717A;
    width: 92%;
}

.history-filter {
        grid-gap: 10px;
        padding: 0 10px;
        display: grid;
        justify-content: end;
        align-items: center;
        grid-template-columns: auto auto auto;
        padding: 6px 10px;

    select {
        width: 100%;
        padding: 6px;
        border: 1px solid lightgray;
        border-radius: 5px;
    }
}


//switch
.switchBox {
    input[type="checkbox"] {
        height: 0;
        width: 0;
        visibility: hidden;
    }

    label {
        cursor: pointer;
        text-indent: -9999px;
        width: 60px;
        height: 25px;
        background: #71717A;
        display: block;
        border-radius: 100px;
        position: relative;
    }

    label:after {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        width: 15px;
        height: 15px;
        background: #fff;
        border-radius: 90px;
        transition: 0.3s;
    }

    input:checked+label {
        background: #02468B;
    }

    input:checked+label:after {
        left: calc(100% - 5px);
        transform: translateX(-100%);
    }

    label:active:after {
        width: 30px;
    }
}

.limit_other_filter table {
    width: 100%;
}