.text-center {
    text-align: center;
}

// .iHSyYe {
//     // display: block !important;
//     // align-items: center;
//     justify-content: center !important;
//     font-size: 12px !important;
// }
// .gUALXj{
//     justify-content: center !important;
// }
.data_content {
    position: relative;
    top: 20px;
}

.data_table {
    margin-top: 0 !important;
}

.campaign_btn {
    margin: 10px;

    .expand-btn {
        max-width: 100%;
        padding: 6px;
        border-radius: 5px;
        background-color: #244d72;
        color: white;
        font-size: 14px;
        transition: .3s ease;
        z-index: 100;
        border: 2px solid #244d72;
        text-align: center;
    }

    .radio-buttons {
        border: 1px solid #244d72;
        padding: 6px 8px 6px 8px;
        border-radius: 5px;
    }

    .search-btn {

        .inner-search-btn {
            width: 100%;
            position: relative;

            input {
                width: 100%;
                float: left;
                border: 1px solid lightgray;
                border-radius: 5px 0 0 5px;
                padding: 8px;
                font-size: 14px;
                color: darkgray;
            }

            button {
                position: absolute;
                right: 0px;
                background-color: #244d72;
                border-radius: 0 5px 5px 0;
                width: 48px;
                height: 39px;
            }

        }

    }
}

.expand-btn:hover {
    color: #244d72;
    background-color: white;
    border: 2px solid #244d72;
}



.vertical_table {
    text-align: end;

    button {
        background: #244d72;
        color: white;
        font-size: 14px;
        font-weight: 400;
        padding: 5px 20px;
        border: 1px solid white;
    }

    .activeFilter {
        background: dodgerblue;
    }

}

.weekly_table {
    width: 100%;
    margin: auto;
    border-radius: 0;
    box-shadow: none;

    tr {

        border-top: 1px solid #e0e0e0;
    }


}

.vertical_table table {
    width: 100%;
    background: white;
    border: 1px solid #e0e0e0;
}

.weekly_content {
    background: #F5F7FB;

    .filter_list {
        padding: 5px 10px;
    }


}

.week_filter {
    padding: 5px;

    button {
        background: #244d72;
        color: white;
        font-size: 14px;
        font-weight: 400;
        padding: 5px 22px;
        border: 1px solid white;
    }
}

.cal {
    width: 70%;
    border: 1px solid darkgray;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
    /* Firefox */
}

.filter_select {
    position: relative;

    .apply_btn {
        position: absolute;
        top: 4px;
        background: aqua;
        height: 37px;
        right: 0;
        background: #244d72;
        color: white;
        border-radius: 0 5px 5px 0;
    }
}

.campaign_btn {
    margin: 10px;
    align-items: center;
    display: grid;
    grid-template-columns: 0.5fr 1fr 1fr 1fr 3fr;
    grid-gap: 8px;

    .radio-buttons {
        max-width: 100%;
        display: flex;
        justify-content: center;
    }
}

.target_acos_modal {
    .target_acos_body {
        span {
            cursor: pointer;
            display: inline-block;
            background: #244d72;
            color: white;
            font-size: 14px;
            font-weight: 400;
            padding: 5px 22px;
            border: 1px solid white;
            width: 80%;
            border: 2px solid #244d72;
        }

        span:hover {
            color: #244d72;
            background: white;
            border: 2px solid #244d72;
        }

        .active {

            color: #244d72;
            background: white;
            border: 2px solid #244d72;
        }

    }

    button {
        background: #244d72;
        color: white;
        font-size: 14px;
        font-weight: 400;
        padding: 5px 22px;
        border: 1px solid white;


    }

    label {
        padding: 0 10px 0 25px;
    }

    input {
        display: inline;
        width: 30% !important;
    }

}

.atac_div span {
    font-size: 11px;
    color: gray;
}

.second_row_btn {
    justify-content: end;

    .expand_btn {
        cursor: pointer;
        max-width: 100%;
        padding: 6px;
        border-radius: 5px;
        background-color: transparent;
        color: #244d72;
        font-size: 14px;
        transition: .3s ease;
        z-index: 1;
        border: 2px solid #244d72;
        text-align: center;
    }
    .expand_btn:hover{
        color: white;
        background-color: #244d72;
        border-radius: 5px;
    }
}
.filter_select {
    .searchWrapper {
        background: white;
        overflow: hidden;
    }
}



.dot {

    display: flex;
    // grid-template-columns: 1fr 5fr;
    align-items: center;

    p {
        min-width: 8px;
        height: 8px;
        border-radius: 50%;

    }

    div {
        margin-left: 15px !important;
    }
}

.green_dot p {
    background: green !important;
}

.red_dot p {
    background: red !important;
}

.second_row_btn {
    display: grid;
    justify-content: end;
    grid-auto-flow: column;
    grid-gap: 9px;
    margin-right: 6px;
}

.order_input {
    position: relative;


    input {
        border: 1px solid lightgray;
        padding: 7px;
        margin-right: 10px;
        border-radius: 3px;
    }

    button.add_filter.order-btn.btn.btn-primary {
        position: absolute;
        top: 0;
        right: 9px;
        padding: 6px 10px;
        font-size: 14px;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        background: #244d72;

    }
}