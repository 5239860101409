#wrapper {
    padding-left: 0;
    transition: all 0.5s ease;
    position: relative;
}

#sidebar-wrapper {
    z-index: 1000;
    position: fixed;
    left: 306px;
    width: 0;
    height: 100%;
    margin-left: -306px;
    overflow-y: auto;
    overflow-x: hidden;
    transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
    width: 60px;
}

.sidebar_container {
    background: var(--sidebar-bg-color);

    .sidebar-nav {
        width: 306px;
        margin: 0;
        padding: 0;
        list-style: none;
        padding-top: 30px;
        border-top: 1px solid #D4D4D4;

        .fa {
            color: var(--sidebar-icon-color);
        }

        ul {
            padding: 0;
        }

        li {
            text-indent: 10px;
            padding-bottom: 15px;
            cursor: pointer;

            a {
                display: block;
                text-decoration: none;
                color: var(--sidebar-text-color);
                font-size: 14px;
                padding: 8px 0;
                font-weight: 500;
                line-height: 21px;
                width: 90%;
                border-radius: 0 20px 20px 0;


                i.fa {
                    font-size: 18px;
                    width: 60px;
                }

            }

        }

        .i-ad {
            width: 60px;
            font-size: 18px;
        }
    }

    .fa-caret-down {
        float: right;
        padding-right: 8px;
    }

    .dropdown-content {
        display: none;
        transition: all 0.5s ease;
    }


    .dropdown-content.show {
        display: block;
        background-color: rgba(255, 255, 255, 0.1);
        padding-top: 10px;
        transition: all 0.5s ease;
        margin-bottom: 10px;
    }

    .active a,
    .active .fa {
        background: #F5F7FB;
        border-radius: 0 20px 20px 0;
        color: black !important;
        border: none;
        width: 90%;

    }
}

.logo_text {
    width: 200px;
}

.toggled .logo_text {
    display: none !important;
    padding: 0 !important;
    transition: .5s;
}

.sidebar-nav>li>a:hover,
.sidebar-nav>li.active>a {
    background: #F5F7FB;
    border-radius: 0 20px 20px 0;
    color: black;
    border: none;
    width: 90%;

}


.sidebar-nav>li>a:hover>.fa {
    color: black !important;
}


.sidebar-nav {

    .active,
    .sub-active {
        .fa {
            color: white;
        }
    }

    li.sub-active>a {
        background: #F5F7FB;
        border-radius: 0 20px 20px 0;
        color: black !important;
        border: none;
        width: 90%;
    }

    li>a:hover {
        background: #F5F7FB;
        border-radius: 0px 20px 20px 0px;
        color: black !important;
        border: none;
        width: 90%;
    }

    li>a:hover>i {
        color: black !important;
    }
}

.sidebar-head {
    width: 306px;
    margin: 0;
    padding: 20px 0;
    list-style: none;
    border-top: 1px solid #D4D4D4;

    .fa {
        color: var(--sidebar-icon-color);
    }

    li {
        text-indent: 10px;

        cursor: pointer;

        a {
            display: block;
            text-decoration: none;
            color: var(--sidebar-text-color) !important;
            font-size: 14px;
            // padding: 8px 0;
            font-weight: 500;
            // line-height: 21px;
            width: 90%;
            border-radius: 0 20px 20px 0;


            i.fa {
                font-size: 18px;
                width: 60px;
            }

        }

    }


}

.vertical_table {
    h5 {
        padding: 10px 10px 10px 16px !important;
        float: right;

        button {
            background: #244d72;
            color: white;
            font-size: 14px;
            font-weight: 400;
            padding: 5px 22px;
            border: 1px solid white;
        }
    }

}